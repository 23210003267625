.App {
  background: #363636;
}
.main {
  width: 80%;
  text-align: center;

  font-size: 265%;
  height: auto;
  margin: 0 auto;
  padding: 10px;
  position: relative;
  overflow: hidden;
}
table {
  width: 100%;
  border-spacing: 0;
}
td {
  width: 20%;
  position: relative;
}
td:after {
  content: '';
  display: block;
  margin-top: 100%;
}
.letter {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: gold;

  text-align: center;
  font-size: 100%;

  border: 1px solid black;
  border-top: 0;
  caret-color: transparent;

}
input.letter:focus {
  outline-width: 0;
  caret-color: transparent;
}

.unsubmitted {
  background: #c5c5c5;
}

.notpresent {
  background: #868683
}

.present {
  background: #e5cb6f;
}

.correct {
  background: #609349;
}
.current {
  background: #af5dc2;
}

.win {
  color: aliceblue;
}